
<template>
  <div class="edit_insurance">
    <div class="title">新增开票点 {{form.nowCompany}}</div>
    <el-form :model="form" ref="form" label-width="5rem" class="ruleForm" :rules="rules">
      <!-- 编号 名称 企业名称 开票终端 备注 -->
      <!-- no name companyId terminalId remark -->
      <el-form-item label="编号" prop="no">
        <el-input v-model="form.no" disabled></el-input>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入开票点名称" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="companyId">
       <!-- <el-input v-model="form.companyId" placeholder="请输入企业名称" maxlength="20" disabled></el-input> -->
         <el-select v-model="form.companyId" placeholder="请选择企业名称">
          <el-option v-for="(item,index) in companyNameList" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开票终端" prop="terminalId"> 
        <el-select v-model="form.terminalId" placeholder="请选择开票终端">
          <el-option v-for="(item,index) in billingTerminalList" :key="index" :label="item.kpddm" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入备注" maxlength="100" ></el-input>
      </el-form-item>

      <el-form-item class="btn">
        <el-button type="primary" @click="submitForm()" :loading="isLoading">保存</el-button>
        <el-button @click="back" class="cancel_btn">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { findPointNo, findTerminalList, addPoint } from '@/api/setInvoice'
export default {
  data() {
    
    return {
      className: "",
      // 编号 名称 企业名称 开票终端 备注
      // no name terminalId terminalId remark
      form: {
        no:'', //编号
        name: '',  //名称
        // companyId:'', //企业名称
        companyId: this.getToken('companyId'), //企业名称id
        nowCompany: this.getToken('nowCompany'), //企业名称
        terminalId:'', //开票终端
        remark:'',//备注
      },
       companyNameList: [ //企业名称列表
             {label: this.getToken('nowCompany'),value: this.getToken('companyId')},
             ], 
       billingTerminalList: [ //开票终端列表
             {label: '开票终端1',value: '0'},
             {label: '开票终端2',value: '1'},
            ], 
      rules: {
        name: [
          { required: true, message: '请输入开票点名称', trigger: 'blur' },
        ],
        companyId: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        terminalId: [
          { required: true, message: '请输入开票终端', trigger: 'blur' },
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' },
        ],
      },
      isLoading: false,

    }
  },

  methods: {
    changeGroup(){

    },

  //编码
    getCode() {
       findPointNo({companyId: this.getToken('companyId'),}).then(res => {
            if (res.success) {
              console.log(res)
               this.form.no = res.data;
               } else {
                einvAlert.error("提示",res.msg)
              }
            }).catch(err => {
             einvAlert.error("提示",err)
            })
    },
  //根据企业名称查找开票终端
    getTerminalList() {
       findTerminalList({companyId: this.getToken('companyId'),}).then(res => {
            if (res.success) {
              console.log(res)
              //  this.form.no = res.data;
              this.billingTerminalList = res.data
               } else {
                einvAlert.error("提示",res.msg)
              }
            }).catch(err => {
             einvAlert.error("提示",err)
            })
    },




 // 保存
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {...this.form, type: this.$route.query.category, userId: this.getToken('userId'),  };
          addPoint(data).then(res => {
             this.isLoading = true;
            if (res.success) {
                this.isLoading = false;
                this.$router.push('/install/invoicingPoint')
               } else {
                einvAlert.error("提示",res.msg)
              }
              
            }).catch(err => {
             
            //  einvAlert.error("提示",err)
            })
        }
      });
    },
    back() {
      this.$router.push('/install/invoicingPoint');
    },

   
  },

  mounted() {
    // this.accountCapitalName();
    // this.init();
    this.getCode();
    this.getTerminalList();
  }

}
</script>
<style lang="less" scoped>
.edit_insurance {
  height: calc(100% - 60px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    font-weight: 700;
    color: #333333;
    font-size: 18px;
    padding: 18px 0;
    width: calc(100% - 40px);
    border-bottom: 1px solid #dcdcdc;
  }
  .ruleForm {
    margin: 40px auto;
    .el-form-item__content {
      .el-input__inner {
        width: 420px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      .el-input.is-disabled {
        .el-input__inner {
          background-color: #f6f6f6;
        }
      }
    }

    .btn {
      text-align: center;
      .el-button {
        width: 100px;
      }
      .cancel_btn {
        margin-left: 20px;
      }
    }
  }
}
.el-form-item__content {
  text-align: center !important;
}

</style>